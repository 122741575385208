import { formatDate } from '@angular/common';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import jsPDF from 'jspdf';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import html2canvas from 'html2canvas';
import { Archivo } from '../interfaces/archivo.interface';
import { AbstractControl, ValidationErrors } from '@angular/forms';
import { Remesa } from '../interfaces/remesa.interface';
import { Correo } from '../interfaces/correo.interface';
import { ParteViajero } from '../interfaces/parteViajero.interface';
import { ParteViajeroN } from '../interfaces/parteViajeroN.interface';
import { BehaviorSubject, Subject } from 'rxjs';
import * as XLSX from 'xlsx';



@Injectable({
  providedIn: 'root'
})
export class MisFuncionesService {
  headers = new HttpHeaders({
    'Content-Type':'application/x-www-form-urlencoded',
    'Tp-Token': localStorage.getItem('tp-Token') || ''
  });

  
  constructor(private http: HttpClient) { }

  /* -------------------------------------------------------------------------- */
  /*                            FUNCIONES DE PASSWORD                           */
  /* -------------------------------------------------------------------------- */

  controlPasswords(campo1: string, campo2: string){
    return (formGroup: AbstractControl): ValidationErrors | null => {
      const pass1 = formGroup.get(campo1)?.value;
      const pass2 = formGroup.get(campo2)?.value;


      if(pass1 !== pass2) {
        formGroup.get(campo2)?.setErrors({noIguales: true});
        formGroup.get(campo2)?.touched;
        return {noIguales: true}
      }
      formGroup.get(campo2)?.setErrors(null);
      return null;
    }
  }

  encriptar(data: string){
    const url = `${ environment.base_url }/Encriptar`;
    return this.http.post(url, data, {headers: this.headers})
                    .pipe(
                      map(
                        (resp:any)=>{
                          const respuesta = {status:resp.status, detalle:resp.detalle};
                          return respuesta;
                        }
                      )
                    );
  }

  letraDni(dni:string){
    let letra:string = '';
    const tmpDni: number = Number(dni);
    const resto: number = tmpDni % 23;
    switch (resto) {
      case 0:
        letra = 'T';
        break;
      case 1:
        letra = 'R';
        break;
      case 2:
        letra = 'W';
        break;
      case 3:
        letra = 'A';
        break;
      case 4:
        letra = 'G';
        break;
      case 5:
        letra = 'M';
        break;
      case 6:
        letra = 'Y';
        break;
      case 7:
        letra = 'F';
        break;
      case 8:
        letra = 'P';
        break;
      case 9:
        letra = 'D';
        break;
      case 10:
        letra = 'X';
        break;
      case 11:
        letra = 'B';
        break;
      case 12:
        letra = 'N';
        break;
      case 13:
        letra = 'J';
        break;
      case 14:
        letra = 'Z';
        break;
      case 15:
        letra = 'S';
        break;
      case 16:
        letra = 'Q';
        break;
      case 17:
        letra = 'V';
        break;
      case 18:
        letra = 'H';
        break;
      case 19:
        letra = 'L';
        break;
      case 20:
        letra = 'C';
        break;
      case 21:
        letra = 'K';
        break;
      case 22:
        letra = 'E';
        break;
    
      default:
        break;
    }
    return letra;
  }

  validarDni(dni:string){
    let validacion: boolean = false;
    const numeroDni: string = this.left(dni, dni.length -1);
    const letraDni: string = this.right(dni, 1);
    if (letraDni == this.letraDni(numeroDni)){validacion = true;}
    return validacion;
  }

  cabeceras(){
    const headers = new HttpHeaders({
      'Content-Type':'application/x-www-form-urlencoded',
      'Tp-Token': localStorage.getItem('tp-Token') || ''
  
  
      // 'X-API-KEY': this.token,
      // 'x-api-key': this.token, 
    });

    return headers;
  }

  validarEmail(email: string) {
    var re = /\S+@\S+\.\S+/;
    return re.test(email);
  }

  encriptarHora(hora:number){
    // hora debe tener 10 dígitos.
    const numStr = this.rellenarcon0(hora.toString(), 10);
    const encryptedStr = [
      numStr[7], // posición 0 pasa a ser la 7
      numStr[3], // posición 1 pasa a ser la 3
      numStr[2], // posición 2 se mantiene
      numStr[6], // posición 3 pasa a ser la 6
      numStr[9], // posición 4 pasa a ser la 9
      numStr[1], // posición 5 pasa a ser la 1
      numStr[4], // posición 6 pasa a ser la 4
      numStr[0], // posición 7 pasa a ser la 0
      numStr[5], // posición 8 pasa a ser la 5
      numStr[8], // posición 9 pasa a ser la 8
    ].join('');
  
    return parseInt(encryptedStr, 10);
  }

  desencriptarHora(hora:number){
    const encryptedStr = hora.toString();
    const decryptedStr = [
      encryptedStr[7], // posición 7 pasa a ser la 0
      encryptedStr[5], // posición 5 pasa a ser la 1
      encryptedStr[2], // posición 2 se mantiene
      encryptedStr[1], // posición 3 pasa a ser la 1
      encryptedStr[6], // posición 4 pasa a ser la 6
      encryptedStr[8], // posición 5 pasa a ser la 8
      encryptedStr[3], // posición 6 pasa a ser la 3
      encryptedStr[0], // posición 0 pasa a ser la 7
      encryptedStr[9], // posición 8 pasa a ser la 9
      encryptedStr[4], // posición 9 pasa a ser la 4
    ].join('');
  
    return parseInt(decryptedStr, 10);
  }

   /* -------------------------------------------------------------------------- */
   /*                              FUNCIONES FORMATO                             */
   /* -------------------------------------------------------------------------- */

   poner00(numero: number){
     if (numero < 10) {
       return "0" + numero;
    }else{
      return numero.toString();
    }
   }

   rellenarcon0(numero: string, longitud: number){
    return this.right('0000000000000000000000000000000000000000' + numero,longitud);
   }

   left(cadena: string, posiciones: number){
     return cadena.substring(0,posiciones);
   }

   right(cadena: string, posiciones: number){
     return cadena.substring(cadena.length -  posiciones,  cadena.length);
   }

   sinTildes(cadena: string){
    // return cadena.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    return cadena.normalize("NFD").replace(/[\u0300-\u0301]/g, "");

    // const acentos = {'á':'a','é':'e','í':'i','ó':'o','ú':'u','Á':'A','É':'E','Í':'I','Ó':'O','Ú':'U'};
	  // return cadena.split('').map( letra => acentos[letra] || letra).join('').toString();	
   }

   sintTildesniN(cadena: string){
    return cadena.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
   }

   eliminarBarra(cadena: string){
    // Elimina el caracter / del texto para que no rompan las búsquedas.
    return cadena.replace(/\//g,'');

   }

   eliminarEspaciosEnBlanco(cadena: string){
    return cadena.replace(/\s/g, '');
   }

  /* -------------------------------------------------------------------------- */
  /*                             FUNCIONES NUMÉRICAS                            */
  /* -------------------------------------------------------------------------- */

  poner0(campo:any){
    const numero = parseFloat(campo);
    if (isNaN(numero)){
      return 0;
    }else{
      return numero;
    }
  }

  round(preNumero: number, decimales:number){
    // return parseFloat(numero.toFixed(decimales));
    const factor = 10 ** decimales;
    // const ajuste = 0.000001; // Ajuste para evitar problemas de redondeo
    // const numero: number = this.preRound(preNumero, decimales + 1); 
    const numero: number = preNumero; 
    // Si el último número es cinco fuerzo a que sume 0.001 para que haga bien el redondeo.
    // const numeroComoCadena = numero.toString();
    // const ultimoDecimalComoCadena = numeroComoCadena.slice(-1);
    // console.log(numero);
    // if (ultimoDecimalComoCadena == '5'){numero += 0.001};
    const numeroMultiplicado = numero * factor;
    const numeroRedondeado = Math.round(numeroMultiplicado + Number.EPSILON);
    return numeroRedondeado / factor;
  }
  // round(numero: number, decimales: number){
  //   const factor = 10 ** decimales;
  //   const numeroRedondeado = Math.round((numero + ajuste) * factor + Number.EPSILON) / factor;
  //   return parseFloat(numeroRedondeado.toFixed(decimales));
  // }
  

  preRound(numero: number, decimales: number){
    const factor = 10 ** decimales;
    const numeroMultiplicado = numero * factor;
    const numeroRedondeado = Math.round(numeroMultiplicado + Number.EPSILON);
    return numeroRedondeado / factor;
  }

  cambiarPuntoaComa(numero:number){
    return numero.toString().replace(/\./g,',');

  }

  int(numero:number){
    //Devuelve el entero sin redondear
    return Math.trunc(numero);
  }

  parteDecimal(numero:number, decimales: number){
    const decimal: string = (numero.toString().split('.')[1])?this.left(numero.toString().split('.')[1] + '00000000000000000', decimales): '00';
    return decimal;
  }

  /* -------------------------------------------------------------------------- */
  /*                             FUNCIONES DE ARRAY                             */
  /* -------------------------------------------------------------------------- */

  ordenarArray(array: any[], campoOrden: string, desc: boolean){
    //Parece que en principio el tipo de datos da igual, podemos tratarlo todo como strings. Eso sí las fechas hay que enviarlas en formato UNIX.
    const comando: string = `array.sort((a, b) => (a.${campoOrden} > b.${campoOrden})? -1 : 1);`;
    eval(comando);
    if (desc){array.reverse();}
    return array;
  }

  ordenarArrayIA(array: any[], campoOrden: string, desc: boolean, numerico: boolean = false) {
    //Con esto ya funciona para campos numéricos. Solamente hay que indicar que es campo numérico.
    // Función de comparación personalizada para campos numéricos
    const compare = (a: any, b: any) => {
      const valueA = a[campoOrden];
      const valueB = b[campoOrden];
  
      if (numerico) {
        return desc ? valueB - valueA : valueA - valueB;
      }
  
      // Si no son números, compáralos como cadenas
      return desc ? String(valueB).localeCompare(String(valueA)) : String(valueA).localeCompare(String(valueB));
    };
  
    // Ordena el array utilizando la función de comparación
    array.sort(compare);
  
    return array;
  }

  /* -------------------------------------------------------------------------- */
  /*                             FUNCIONES DE COMBO                             */
  /* -------------------------------------------------------------------------- */

  comboParcelas(){
    return ['Acampada', 'Bungalow4', 'Bungalow5'];
  }

  funcionesTpv(){
    return ['-1', '+1', 'ALBARÁN', 'APARCAR', 'BORRAR', 'CAJAS', 'CAJON', 'CAMPING', 'COBRAR', 'CUENTA', 'DEVOLUCIÓN', 'LEER', 'LIMPIAR', 'MESA', 'PALÉS', 'TARIFA1','TARIFA2','TARIFA3', 'ULT.TICKET', 'UNIDADES'];
  }

  /* -------------------------------------------------------------------------- */
  /*                             FUNCIONES DE FECHA                             */
  /* -------------------------------------------------------------------------- */

  ahora(){
    const gmt: string = environment.gmt;
    return formatDate(Date.now(), 'yyyy-MM-ddTHH:mm','en-US', gmt);
  }

  ahoraFormatoNumero(){
    const gmt: string = environment.gmt;
    return formatDate(Date.now(), 'HHmm','en-US', gmt);
  }

  hora(fecha: string){
    const tiempo: Date = new Date(fecha);
    return `${this.poner00(tiempo.getHours())}:${this.poner00(tiempo.getMinutes())}`;
  }

  anadirHorasMinutosAFecha(fecha: Date, horasMinutos: string){
   // Divide la cadena en horas y minutos
   const [hours, minutes] = horasMinutos.split(':').map(Number);
   fecha.setHours(hours);
   fecha.setMinutes(minutes);
   return fecha;
  }

  esFechaValida(campo:any){
    
    const isValidDate = Date.parse(campo);
    
    if (isNaN(isValidDate)) {
      return false;
    }else{
      return true;
    }
  }

  haceTresMeses(){
    const fecha = new Date();
    let mes:number = 0;
    if(fecha.getMonth() > 2){
      mes = fecha.getMonth() - 3;
    }else{
      mes = 0;
    }
    
    // var dias = 7; // Número de días a agregar
    // fecha.setDate(fecha.getDate() - 90);
    const fecha2 = new Date(fecha.getFullYear(), mes, 1);
    
    return formatDate(fecha2, 'yyyy-MM-dd','en-US');

  }

  haceUnaSemana(){
    const fecha = new Date();
    // var dias = 7; // Número de días a agregar
    fecha.setDate(fecha.getDate() - 7);
    
    return formatDate(fecha, 'yyyy-MM-dd','en-US');

  }

  sumarDiasFecha(fecha: string, dias: number){
    const nfecha = new Date(fecha);
    // var dias = 7; // Número de días a agregar
    nfecha.setDate(nfecha.getDate() + dias);
    
    return formatDate(nfecha, 'yyyy-MM-dd','en-US');

  }

  hoy(){
    return formatDate(Date.now(), 'yyyy-MM-dd','en-US');
  }

  hoyFormatoNumero(){
    return formatDate(Date.now(), 'yyyyMMdd','en-US');

  }

  primerDiaDelAno(){
    const date = new Date();
    const retorno =  this.fechaaString(new Date(date.getFullYear(), 0, 1));
    return retorno;
  }

  ultimoDiaDelAno(){
    const date = new Date();
    const retorno =  this.fechaaString(new Date(date.getFullYear(), 11, 31));
    return retorno;
  }

  ultimoDiaDelMes(){
    const date = new Date();
    const retorno =  this.fechaaString(new Date(date.getFullYear(), date.getMonth() + 1, 0));
    return retorno;
  }

  primerDiaDelMes(){
    const date = new Date();
    const retorno =  this.fechaaString(new Date(date.getFullYear(), date.getMonth(), 1));
    return retorno;
  }

  year(campo:string){
    return parseInt(campo);
  }

  formatearFecha(fecha:string, caracterSeparador: string = '/'){
    const mfecha = new Date(fecha);
    // return mfecha.getDate() + "/" + (mfecha.getMonth() + 1) + "/" + mfecha.getFullYear(); 
    return mfecha.getDate() + caracterSeparador + this.poner00((mfecha.getMonth() + 1)) + caracterSeparador + mfecha.getFullYear(); 

  }

  formatearFechaPosterior2000(fecha:string){
    // Devuelve una fecha posterior al 2000 por ejemplo de 0028-01-01 devuelve 2028-01-01
    const ano: number = Number(this.left(fecha , 4));
    for (let i = ano;  i < 2100; i = i + 100) {
      fecha = i.toString() + this.right(fecha, 6);
    }
    
    return fecha;

  }

  formatearFechaUnix(fecha:number){
    const mfecha = new Date(fecha * 1000);
    return mfecha.getFullYear() + "-" + this.poner00((mfecha.getMonth() + 1)) + "-" + this.poner00(mfecha.getDate()) + "T" + this.poner00(mfecha.getHours()) + ":" + this.poner00(mfecha.getMinutes());
  }

  fechaFormatoUnix(fecha: string){
    return new Date(fecha).getTime() / 1000;
  }

  horaActualFormatoUnix(): number {
    return Math.floor(Date.now() / 1000);
  }

  horasDiferenciaUnix(horaFinal: number, horaInicial: number){
    const differenceInSeconds = Math.abs(horaFinal - horaInicial);
    return differenceInSeconds / 3600;
  }

  secondsToString(seconds:number):string {
    let horas:string = '';
    let minutos: string = '';
    let segundos: string = '';
    const hour = Math.floor(seconds / 3600);
    horas = (hour < 10)? '0' + hour : '' + hour;
    var minute = Math.floor((seconds / 60) % 60);
    minutos = (minute < 10)? '0' + minute : '' + minute;
    var second = seconds % 60;
    segundos = (second < 10)? '0' + second : '' + second;
    return horas + ':' + minutos + ':' + segundos;
  }

  fechaaString(mfecha: Date, caracterSeparador: string = '-'):string{
      // const retorno: string = mfecha.getFullYear() + '-' +  this.poner00((mfecha.getMonth() + 1)) + '-' + this.poner00(mfecha.getDate());
      const retorno: string = mfecha.getFullYear() + caracterSeparador +  this.poner00((mfecha.getMonth() + 1)) + caracterSeparador + this.poner00(mfecha.getDate());
      return retorno;
  }

  formatoEspana(fecha:Date){
    const dia = fecha.getDate().toString().padStart(2, '0');
    const mes = (fecha.getMonth() + 1).toString().padStart(2, '0');
    const año = fecha.getFullYear();

    const fechaFormateada = `${dia}-${mes}-${año}`;
    return fechaFormateada;
  }

  principioDeLosTiempos(){
    return '1980-01-01';
  }

  finDeLosTiempos(){
    return '2999-12-01';
  }

  fechaDesde(fecha: string){
    let desde: Date = new Date(fecha);
        desde.setHours(0,0,0);
    return desde.getTime() / 1000;
  }

  fechaHasta(fecha:string){
    let hasta:Date = new Date(fecha);
    hasta.setHours(23,59,59);
    return hasta.getTime() / 1000;
  }

  fechaEntrada(fechaEntrada:string):number{
    // Esta fecha se utiliza para las entradas en alojamiento. Coge la fecha del formulario y le pone la hora de entrada a las 12:01
    const fecha: Date = new Date(fechaEntrada);
    fecha.setHours(12,1,1);
    const fechaUnix = fecha.getTime()/1000;
    return fechaUnix;
  }

  fechaSalida(fechaSalida:string):number{
    // Esta fecha se utiliza para las salidas en alojamiento. Coge la fecha del formulario y le pone la hora de salida a las 11:59
    const fecha: Date = new Date(fechaSalida);
    fecha.setHours(11,59,1);
    const fechaUnix = fecha.getTime()/1000;
    return fechaUnix;
  }

  sumarMinutos(fecha:Date, minutos: number):Date{
    fecha.setMinutes(fecha.getMinutes() + minutos);
    return fecha;
  }

  fechaDectoConsultas(){
    return this.sumarDiasFecha(new Date().toString(), environment.diasDefectoConsultas * -1);
  }

  diaSemana(fecha: Date) {
    let dia: string = 'Lunes';

    switch (fecha.getDay()) {
      case 0:
        dia = 'Domingo';
        break;
      case 1:
        dia = 'Lunes';
        break;
      case 2:
        dia = 'Martes';
        break;
      case 3:
        dia = 'Miércoles';
        break;
      case 4:
        dia = 'Jueves';
        break;
      case 5:
        dia = 'Viernes';
        break;
      case 6:
        dia = 'Sábado';
        break;
    
      default:
        break;
    }
    return dia;
  }

  calcularEdad(fechaNacimiento: Date, hoy: Date = new Date()) {
    var cumpleanos = new Date(fechaNacimiento);
    var edad = hoy.getFullYear() - cumpleanos.getFullYear();
    var m = hoy.getMonth() - cumpleanos.getMonth();
  
    if (m < 0 || (m === 0 && hoy.getDate() < cumpleanos.getDate())) {
      edad--;
    }
  
    return edad;
  }

  diasEntreFechas(fecha1: Date, fecha2: Date){
    fecha1.setHours(0,0,0);
    fecha2.setHours(0,0,0);
    const diferenciaHoraria = fecha2.getTime() - fecha1.getTime();
    const cantidadDias = Math.ceil(diferenciaHoraria / (1000 * 60 * 60 * 24));
    return cantidadDias;
  }

  /* -------------------------------------------------------------------------- */
  /*                           FUNCIONES DE IMPRESIÓN                           */
  /* -------------------------------------------------------------------------- */

  abrirCajon(){
    // return String.fromCharCode(27) + 'p' + String.fromCharCode(0) + String.fromCharCode(10) + String.fromCharCode(20);
    return String.fromCharCode(27) + String.fromCharCode(0) + String.fromCharCode(10) + String.fromCharCode(20);
  }

  /* -------------------------------------------------------------------------- */
  /*                            FUNCIONES DE ARCHIVO                            */
  /* -------------------------------------------------------------------------- */

  leerConfiguracion(){
  
      return this.http.get("assets/configuracion.txt", {responseType: 'text'})
      .pipe(
        map(
          (resp:any)=>{
            const respuesta = JSON.parse(resp);
            return respuesta;
          }
        )
      )
      ;
  }

  borrarArchivo(archivo:Archivo){
      const url = `${ environment.base_url }/Archivos/borrarArchivo`;
      // console.log(archivo);
      
      
      return this.http.post(url, archivo, {headers: this.headers})
                      .pipe(
                        map(
                          (resp:any)=>{
                            const respuesta = {status:resp.status, detalle:resp.detalle};
                            return respuesta;
                          }
                        )
                      )
                      ;
  
  }

  subirArchivo(archivo:Archivo){
      const url = `${ environment.base_url }/Archivos/subirArchivo`;
      // console.log(archivo);
      
      
      return this.http.post(url, archivo, {headers: this.headers})
                      .pipe(
                        map(
                          (resp:any)=>{
                            const respuesta = {status:resp.status, detalle:resp.detalle};
                            return respuesta;
                          }
                        )
                      )
                      ;
  
  }

  exportarExcel(datos: any, nombreArchivo: string): void {
    // Con esta opción omitimos la cabecera.
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(datos, { skipHeader: true });
    const book: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(book, worksheet, nombreArchivo);

    // Guardar el archivo en formato XLS
    XLSX.writeFile(book, nombreArchivo + '.xls');
  }
  
  descargarCSV(data:any, headerList: string[], filename='data', headersTipoString: string[] = []) {
      // Hace falta indicar que campos son tipo string para no transformarlos al cambiar el; y que queden en 0.
  
      let csvData = this.ConvertToCSV(data, headerList, headersTipoString);
      // console.log(csvData)
      let blob = new Blob(['\ufeff' + csvData], { type: 'text/csv;charset=utf-8;' });
      let dwldLink = document.createElement("a");
      let url = URL.createObjectURL(blob);
      let isSafariBrowser = navigator.userAgent.indexOf('Safari') != -1 && navigator.userAgent.indexOf('Chrome') == -1;
      if (isSafariBrowser) {  //if Safari open in new window to save file with random filename.
          dwldLink.setAttribute("target", "_blank");
      }
      dwldLink.setAttribute("href", url);
      dwldLink.setAttribute("download", filename + ".csv");
      dwldLink.style.visibility = "hidden";
      document.body.appendChild(dwldLink);
      dwldLink.click();
      document.body.removeChild(dwldLink);
  }
  
  descargarTxt(texto: string, nombreFichero: string){
   // Hace falta indicar que campos son tipo string para no transformarlos al cambiar el; y que queden en 0.
  
  //  let csvData = "codigo nombre";
   // console.log(csvData)
   let blob = new Blob(['\ufeff' + texto], { type: 'text;charset=utf-8;' });
   let dwldLink = document.createElement("a");
   let url = URL.createObjectURL(blob);
   let isSafariBrowser = navigator.userAgent.indexOf('Safari') != -1 && navigator.userAgent.indexOf('Chrome') == -1;
   if (isSafariBrowser) {  //if Safari open in new window to save file with random filename.
       dwldLink.setAttribute("target", "_blank");
   }
   dwldLink.setAttribute("href", url);
  //  dwldLink.setAttribute("download", 'prueba' + ".txt");
   dwldLink.setAttribute("download", nombreFichero);
   dwldLink.style.visibility = "hidden";
   document.body.appendChild(dwldLink);
   dwldLink.click();
   document.body.removeChild(dwldLink);
  }

  descargarImagen(ruta: string, nombreFichero: string){
    let dwldLink = document.createElement("a");
    let isSafariBrowser = navigator.userAgent.indexOf('Safari') != -1 && navigator.userAgent.indexOf('Chrome') == -1;
    if (isSafariBrowser) {  //if Safari open in new window to save file with random filename.
        dwldLink.setAttribute("target", "_blank");
    }
    dwldLink.setAttribute("href", ruta);
    dwldLink.setAttribute("download", nombreFichero);
    dwldLink.style.visibility = "hidden";
    document.body.appendChild(dwldLink);
    dwldLink.click();
    document.body.removeChild(dwldLink);
   }
  
  ConvertToCSV(objArray:any, headerList:any, headersTipoString: string[]) {
      let array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
      let str = '';
      // let row = 'Línea;';
      let row = '';
      for (let index in headerList) {
          row += headerList[index] + ';';
      }
      row = row.slice(0, -1);
      str += row + '\r\n';
      for (let i = 0; i < array.length; i++) {
          // let line = (i+1)+'';
          let line = '';
          for (let index in headerList) {
             const head = headerList[index];
            //  Si el campo es de tipo string no lo transformamos
             if (headersTipoString.includes(head)){
               line += array[i][head] + ';';
              }else{
                line += this.cambiarPuntoaComa(this.round(this.poner0(array[i][head]),2)) + ';';
              }
          }
          str += line + '\r\n';
      }
      return str;
  }

  // generarParteViajerosPruebas(data: ParteViajeroN){

  //   const url = `${ environment.base_url }/Archivos/generarParteViajeros`;
    
  //   return this.http.post(url, data, {headers: this.headers})
  //                   .pipe(
  //                     map(
  //                       (resp:any)=>{
  //                         const respuesta = {status:resp.status, totalPaginas: resp.totalPaginas, detalle:resp.detalle};
  //                         return respuesta;
  //                       }
  //                     )
  //                   )
  //                   ;

  // }

  generarParteViajeros(data: ParteViajeroN){

    const url = `${ environment.base_url }/Archivos/generarParteViajeros`;
    
    return this.http.post(url, data, {headers: this.headers})
                    .pipe(
                      map(
                        (resp:any)=>{
                          // const respuesta = {status:resp.status, detalle:resp.detalle};
                          const respuesta = {status:resp.status, totalPaginas: resp.totalPaginas, detalle:resp.detalle};
                          let blob = new Blob(['\ufeff' + resp.detalle], { type: 'text;charset=utf-8;' });
                          let dwldLink = document.createElement("a");
                          let url = URL.createObjectURL(blob);
                          let isSafariBrowser = navigator.userAgent.indexOf('Safari') != -1 && navigator.userAgent.indexOf('Chrome') == -1;
                          if (isSafariBrowser) {  //if Safari open in new window to save file with random filename.
                            dwldLink.setAttribute("target", "_blank");
                          }
                          dwldLink.setAttribute("href", url);
                          //  dwldLink.setAttribute("download", 'prueba' + ".txt");
                          dwldLink.setAttribute("download", "parteViajeros.xml");
                          dwldLink.style.visibility = "hidden";
                          document.body.appendChild(dwldLink);
                          dwldLink.click();
                          document.body.removeChild(dwldLink);
                          return respuesta;
                        }
                      )
                    )
                    ;

  }

  generarFacturae(idFactura: number){

    const url = `${ environment.base_url }/Archivos/generarFacturae/${idFactura}`;
    
    return this.http.get(url, {headers: this.headers})
                    .pipe(
                      map(
                        (resp:any)=>{
                          const respuesta = {status:resp.status, detalle:resp.detalle};
                          let blob = new Blob(['\ufeff' + resp.detalle], { type: 'text;charset=utf-8;' });
                          let dwldLink = document.createElement("a");
                          let url = URL.createObjectURL(blob);
                          let isSafariBrowser = navigator.userAgent.indexOf('Safari') != -1 && navigator.userAgent.indexOf('Chrome') == -1;
                          if (isSafariBrowser) {  //if Safari open in new window to save file with random filename.
                            dwldLink.setAttribute("target", "_blank");
                          }
                          dwldLink.setAttribute("href", url);
                          //  dwldLink.setAttribute("download", 'prueba' + ".txt");
                          dwldLink.setAttribute("download", "facturae.xml");
                          dwldLink.style.visibility = "hidden";
                          document.body.appendChild(dwldLink);
                          dwldLink.click();
                          document.body.removeChild(dwldLink);
                          return respuesta;
                        }
                      )
                    )
                    ;

  }

  generarSEPA(data: Remesa[]){

    const url = `${ environment.base_url }/Archivos/generarSEPA`;
    
    return this.http.post(url, data, {headers: this.headers})
                    .pipe(
                      map(
                        (resp:any)=>{
                          const respuesta = {status:resp.status, detalle:resp.detalle};
                          let blob = new Blob(['\ufeff' + resp.detalle], { type: 'text;charset=utf-8;' });
                          let dwldLink = document.createElement("a");
                          let url = URL.createObjectURL(blob);
                          let isSafariBrowser = navigator.userAgent.indexOf('Safari') != -1 && navigator.userAgent.indexOf('Chrome') == -1;
                          if (isSafariBrowser) {  //if Safari open in new window to save file with random filename.
                            dwldLink.setAttribute("target", "_blank");
                          }
                          dwldLink.setAttribute("href", url);
                          //  dwldLink.setAttribute("download", 'prueba' + ".txt");
                          dwldLink.setAttribute("download", "remesa.xml");
                          dwldLink.style.visibility = "hidden";
                          document.body.appendChild(dwldLink);
                          dwldLink.click();
                          document.body.removeChild(dwldLink);
                          return respuesta;
                        }
                      )
                    )
                    ;

  }


  /* -------------------------------------------------------------------------- */
  /*                                FUNCIONES PDF                               */
  /* -------------------------------------------------------------------------- */

  guardarPdfPhp(data:string){
    const url = `${ environment.base_url }/Pdf/guardarPdfPhp`;
    // console.log(url);
    const datos = {
      html : data
    };
    console.log(datos);
    
    return this.http.post(url, datos, {headers: this.headers})
                    .pipe(
                      map(
                        (resp:any)=>{
                          const respuesta = {status:resp.status, detalle:resp.detalle};
                          return respuesta;
                        }
                      )
                    )
                    ;
  }
  
  guardarPdf(archivo:Archivo){
    const url = `${ environment.base_url }/Pdf/guardarPdf`;
    // console.log(url);
    // const datos = {
    //   pdf : data
    // };
    // console.log(data);
    
    return this.http.post(url, archivo, {headers: this.headers})
                    .pipe(
                      map(
                        (resp:any)=>{
                          const respuesta = {status:resp.status, detalle:resp.detalle};
                          return respuesta;
                        }
                      )
                    )
                    ;
  }
  
  descargarPdfVariasPaginas(paginas: HTMLElement[]){
    let pdf = new jsPDF('p', 'mm', 'a4', false);
    let i = 0;
    paginas.forEach(pagina => {
      // Si aumentamos la escala aumentamos calidad pero duplicamos tamaño
      // html2canvas(pagina,{scale: 1, allowTaint: true, width: 850, x: -20, y: -10}).then(canvas => {
      html2canvas(pagina,{scale: 1, allowTaint: true, width: 850, x: -20, y: -10}).then(canvas => {
        let fileWidth = 210;

        // let fileHeight = canvas.height * fileWidth / canvas.width;
        let fileHeight = 295;
        
        const FILEURI = canvas.toDataURL('image/png')
        // const FILEURI = canvas.toDataURL('image/jpeg', 500);
        let position = 0;
        const options = {filename : 'pdf'};
        // console.log(pagina);
        
        // pdf.html(pagina, {
        //   callback: function (doc) {
        //     doc.save(`prueba.pdf`)
        //   }
        // });
        
        // pdf.addImage(FILEURI, 'PNG', 0, position, fileWidth, fileHeight);

        // pdf.addImage(FILEURI, 'PNG', 0, position, fileWidth, fileHeight); esta es la Buena
          pdf.addImage(FILEURI, 'PNG', 0, position, fileWidth, fileHeight, '','FAST');  //esto comprime el pdf.

        // pdf.addImage(FILEURI, 'PNG', mover a lo ancho, mover a lo alto, fileWidth, fileHeight);
        // pdf.addImage(FILEURI, 'JPEG',0, position, fileWidth, fileHeight);
        i++;
        
        if(i == paginas.length){
          pdf.save();
        }else{
          pdf.addPage('a4','p');};
        });     
        }
      );
      
 
  }

  descargarPdf(data: HTMLElement){
    html2canvas(data).then(canvas => {
      
      let fileWidth = 210;
      // let fileHeight = canvas.height * fileWidth / canvas.width;
      let fileHeight = 300;
      
      const FILEURI = canvas.toDataURL('image/png')
      let pdf = new jsPDF('p', 'mm', 'a4');
      let position = 0;
      const options = {filename : 'pdf'};
      pdf.addImage(FILEURI, 'PNG', 0, position, fileWidth, fileHeight)
      
      pdf.save('angular.pdf');
      // pdf.output('dataurlnewwindow');
    
     
      
    });     
  
  }

  mailPdf(paginas:HTMLElement[], nombreArchivo: string = 'angular.pdf', correo: string = '', asunto: string = '', mensaje: string = ''){

    let pdf = new jsPDF('p', 'mm', 'a4',false);
    let i = 0;
    paginas.forEach(pagina => {
      // html2canvas(pagina).then(canvas => {
        // Si aumentamos la escala aumentamos calidad pero duplicamos tamaño
      html2canvas(pagina,{scale: 1, allowTaint: true, width: 850, x: -20, y: -10}).then(canvas => {
      
        let fileWidth = 210;
        // let fileHeight = canvas.height * fileWidth / canvas.width;
        let fileHeight = 300;
        
        const FILEURI = canvas.toDataURL('image/png')
        let position = 0;
        const options = {filename : 'pdf'};
        // pdf.addImage(FILEURI, 'PNG', 0, position, fileWidth, fileHeight);
        pdf.addImage(FILEURI, 'PNG', 0, position, fileWidth, fileHeight, '','FAST'); //Esto comprime el Pdf.
        i++;
        if(i == paginas.length){
            // PARTE PARA GUARDAR PDF EN SERVER  
    
            const archivo: Correo = {
              correo: correo,
              asunto: asunto,
              mensaje: mensaje,
              nombreArchivo: nombreArchivo,
              carpeta: 'PDFS',
              base64textString: btoa(pdf.output())
            };
            this.enviarPdf(archivo).subscribe(
              resp => {
                if(resp.status != 200) {
                  console.log(resp.detalle);
                  
                }else{
                  console.log(resp.detalle);
                }
              }
            );
        }else{
          pdf.addPage('a4','p');};
        });     
      });
   

  }
  // mailPdf(data:HTMLElement, nombreArchivo: string = 'angular.pdf'){
  //   html2canvas(data).then(canvas => {
  //     let fileWidth = 208;
  //     let fileHeight = 300;
      
  //     const FILEURI = canvas.toDataURL('image/png')
  //     let pdf = new jsPDF('p', 'mm', 'a4');
  //     let position = 0;
  //     const options = {filename : 'pdf'};
  //     pdf.addImage(FILEURI, 'PNG', 0, position, fileWidth, fileHeight)
     
  //     // PARTE PARA GUARDAR PDF EN SERVER  
    
  //     const archivo: any = {
  //       nombreArchivo: nombreArchivo,
  //       carpeta: 'PDFS',
  //       base64textString: btoa(pdf.output())
  //     };
  //     this.enviarPdf(archivo).subscribe(
  //       resp => {
  //         if(resp.status != 200) {
  //           console.log(resp.detalle);
            
  //         }else{
  //           console.log(resp.detalle);
  //         }
  //       }
  //     );
  //   });     

  // }

  enviarPdf(correo:Correo){
    const url = `${ environment.base_url }/Archivos/mailPDF`;
    
    return this.http.post(url, correo, {headers: this.headers})
                    .pipe(
                      map(
                        (resp:any)=>{
                          const respuesta = {status:resp.status, detalle:resp.detalle};
                          return respuesta;
                        }
                      )
                    )
                    ;

  }

  /* -------------------------------------------------------------------------- */
  /*                              FUNCIONES DE VOZ                              */
  /* -------------------------------------------------------------------------- */

  leer(texto: string){
    const voice = new SpeechSynthesisUtterance();
    // Objeto de la API
    const jarvis = window.speechSynthesis;
    // const disabledPlay = true;
    // voice.lang = 'En-en';
    voice.lang = 'Es-es';
    voice.text = texto;
    jarvis.speak(voice)
  }

  /* -------------------------------------------------------------------------- */
  /*                             FUNCIONES DE IMÁGEN                            */
  /* -------------------------------------------------------------------------- */


  devuelveImagenBase64(ruta: string){
     // Esta funcion devuelve una promesa de imágen codificada en base64 a partir de  la ruta indicada. 

    //  para leerla: 
    // this.misFuncionesService.devuelveImagenBase64(ruta).then(resp =>{
    //   console.log(`promise result: ${resp}`);
    // })


    return new Promise<string>(resolve => {
      fetch(ruta)
        .then((res) => res.blob())
        .then((blob) => {
            // Read the Blob as DataURL using the FileReader API
            const reader = new FileReader();
            reader.onloadend = () => {
                // console.log('blob: ', reader.result);
                // Logs data:image/jpeg;base64,wL2dvYWwgbW9yZ...
                // El reader ya me devuelve un txt en base64, pero sobra la parte de data: image...base64, que hay que eliminar.
                // Localizar posición base64,
                const imagen = reader.result!.toString();
                const longCabecera: number = imagen.indexOf('base64,') + 7;
                // console.log('posicion: ', longCabecera);
                const archivo: string = this.right(imagen, imagen.length - longCabecera);
    
                resolve(archivo);

                
                
            };
            reader.readAsDataURL(blob);
        })
    });
  }

  /* -------------------------------------------------------------------------- */
  /*                           FUNCIONES DE NAVEGACIÓN                          */
  /* -------------------------------------------------------------------------- */

  openNewTab(url: string){
    // Debería abrir una pestaña nueva con la url, hasta que funcione el enrutamiento desde la barra de direcciones solo funciona en Xampp
    window.open(url, '_blank');
  }

  AbrirNuevaVentana(url: string) {
    window.open(url, 'newwindow', 'width=1024,height=600');
  }

  focusId(id: string){
    id = '#' + id;
    // setTimeout(() => {
      const input = document.querySelector(id) as HTMLInputElement;
      input.focus();
    // }, 500);
  }

  makeElementDraggable(el: HTMLElement) {
    let pos1 = 0, pos2 = 0, pos3 = 0, pos4 = 0;

    el.onmousedown = dragMouseDown;

    function dragMouseDown(e: MouseEvent) {
      e.preventDefault();
      pos3 = e.clientX;
      pos4 = e.clientY;
      document.onmouseup = closeDragElement;
      document.onmousemove = elementDrag;
    }

    function elementDrag(e: MouseEvent) {
      e.preventDefault();
      pos1 = pos3 - e.clientX;
      pos2 = pos4 - e.clientY;
      pos3 = e.clientX;
      pos4 = e.clientY;
      el.style.top = (el.offsetTop - pos2) + "px";
      el.style.left = (el.offsetLeft - pos1) + "px";
    }

    function closeDragElement() {
      document.onmouseup = null;
      document.onmousemove = null;
    }
  }


  /* -------------------------------------------------------------------------- */
  /*                    OBSERVABLE PARA REFRESCAR COMPONENTES                   */
  /* -------------------------------------------------------------------------- */

  private refreshSubject = new Subject<void>();
  // Observable al que se pueden suscribir los componentes
  refresh$ = this.refreshSubject.asObservable();

  // Método para emitir el evento de refresco
  triggerRefresh() {
    this.refreshSubject.next();
  }


  
 

  /* -------------------------------------------------------------------------- */
  /*                                   PRUEBAS                                  */
  /* -------------------------------------------------------------------------- */





    

}
